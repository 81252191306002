@import '~antd/dist/antd.css';

$sm: 576px;
$md: 768px;
$lg: 992px;
$xlg: 1200px;


.ant-layout-header {
  padding-left: 0;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.header {
  display: flex;
  justify-content: space-between;

  .logo {
    color: #fff;
    font-size: 1.2rem;
    width: 200px;
    text-align: center;

  }

  .user-top-bar {
    height: 100%;
    color: #fff;
    cursor: pointer;
  }
}

.list-image {
  width: 100px;
  max-height: 100px;
  cursor: pointer;
}

.show-image img {
  width: 100%;
  max-width: 80vh;
  max-height: 80vh;
}

.portlet__actions {
  .action__search {
    margin-bottom: 18px;
    @media (min-width: $lg) {
      max-width: 400px;
    }
  }
}

.dnd-grid {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__item {
    width: 200px;
    margin: 5px;

    :before {
      content: "";
      padding-top: 100%;
    }

    cursor: move;
  }
  &__image-wrapper {
    width: 100%;
  }
  &__image {
    flex-grow: 1;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50%;
  }
  &__image-title {
    text-align: center;
  }
}
.positions-wrapper__selector {
  min-width: 200px;
  margin-left: 5px;
}
.portlet {
  &__actions {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;

    > * {
      margin-right: 1rem;
    }

    @media (max-width: $lg) {
      flex-wrap: wrap;
      > :not(a) {
        margin-left: 0;
      }
    }

  }
}
.form__inline-select {
  min-width: 250px;
}
.pull-right {
  float:right;
}
.clickable {
  cursor: pointer;
}
.ant-btn {
  margin-right: 0.5rem;
}